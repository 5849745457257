import Vue from 'vue';
import VueI18n from 'vue-i18n';
import zhCn from '@/i18n/zh-cn.json';
import zhtw from '@/i18n/zh-tw.json';
import en from '@/i18n/en.json';
import th from '@/i18n/th.json';
import ms from '@/i18n/ms.json';
import bn from '@/i18n/bn.json';
import enLocale from '@ts/element-ui/lib/locale/lang/en';
import zhLocale from '@ts/element-ui/lib/locale/lang/zh-CN';

Vue.use(VueI18n);

const messages = {
  en: { ...en, ...enLocale },
  'zh-cn': { ...zhCn, ...zhLocale },
  'zh-tw': { ...zhtw },
  th: { ...th },
  ms: { ...ms },
  bn: { ...bn }
};
console.log('messages', messages);
let locale = localStorage.getItem('locale');
if (!locale) {
  locale = 'zh-cn';
  localStorage.setItem('locale', locale);
}
const i18n = new VueI18n({
  locale, // 设置地区
  fallbackLocale: 'zh-cn',
  messages
});

export default i18n;
